import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    resetButtonIsDisabled: state.searchForm.resetButtonIsDisabled
  };
};

const ResetButton = ({
  resetButtonIsDisabled,
  resetHandler,
  formResetHandler
}) => {
  const { t } = useTranslation();

  return (
    <div className="search-popup__header-action right-text">
      <button
        className={classNames('search-popup__header-btn', {
          disabled: resetButtonIsDisabled
        })}
        onClick={resetHandler || formResetHandler}
        data-cy="search-popup-reset-btn"
      >
        <span
          className="search-popup__header-btn_text"
          data-cy="reset-btn-text"
        >
          {t('common.reset')}
        </span>
      </button>
    </div>
  );
};

ResetButton.propTypes = {
  formResetHandler: PropTypes.func,
  resetHandler: PropTypes.func,
  resetButtonIsDisabled: PropTypes.bool
};

export default connect(mapStateToProps)(ResetButton);
