import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxOrRadio from '../SearchFormCustomComponents/CheckboxOrRadio';

const HasRepairGroup = () => {
  const { t } = useTranslation();

  return (
    <div className="search-form__group">
      <div className="search-form__has-repair">
        <div className="f-input-group f-input-group--flex">
          {[
            {
              value: '',
              labelText: t('search.form.all'),
              id: 'search-form-all-repair'
            },
            {
              value: 'true',
              labelText: t('search.form.has_repair'),
              id: 'search-form-has-repair'
            },
            {
              value: 'false',
              labelText: t('search.form.has_no_repair'),
              id: 'search-form-has-no-repair'
            }
          ].map(({ value, labelText, id }, key) => (
            <CheckboxOrRadio
              {...{ value, labelText, id }}
              key={key}
              type="radio"
              name="has_repair"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HasRepairGroup;
