import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import FloorFieldsGroup from './FloorFieldsGroup';
import Checkbox from '../SearchFormCustomComponents/Checkbox';

const FloorsGroup = ({ isHiddenHeader }) => {
  const { t } = useTranslation();
  const { change, getFieldState } = useForm();
  const [prevValue, setPrevValue] = useState(null);

  const labelOnClick = event => {
    const currentValue = event.target.value;

    if (prevValue === currentValue) {
      change('floor_last', undefined);
    }
    if (typeof currentValue !== 'undefined') setPrevValue(currentValue);
  };

  const handleChange = event => {
    const floorFirstChecked = event.target.checked;
    const fieldState = getFieldState('floor_from');
    const { value: fieldValue } = fieldState;

    if (floorFirstChecked && +fieldValue === 1) {
      change('floor_from', undefined);
    } else if (!floorFirstChecked) {
      change('floor_first', undefined);
    }
  };

  return (
    <div className="search-form__group">
      <FloorFieldsGroup isHiddenHeader={isHiddenHeader} />
      <Checkbox
        name="floor_first"
        labelText={t('search.form.not_first')}
        handleChange={handleChange}
        id="search-form-floor-not-first"
      />
      {[
        {
          value: 'false',
          labelText: t('search.form.not_last'),
          id: 'search-form-floor-not-last'
        },
        {
          value: 'true',
          labelText: t('search.form.only_last'),
          id: 'search-form-floor-only-last'
        }
      ].map(({ value, labelText, id }, key) => (
        <Checkbox
          {...{ value, labelText, id, labelOnClick }}
          key={key}
          name="floor_last"
          type="radio"
        />
      ))}
    </div>
  );
};

FloorsGroup.propTypes = {
  isHiddenHeader: PropTypes.bool
};

export default FloorsGroup;
