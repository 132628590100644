import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utils/helpers';

const LandAreaFields = ({ isHiddenHeader }) => {
  const { t } = useTranslation();

  return (
    <div className="search-form__fields search-form__fields--land-area f-input-group">
      <div
        className="search-form__fields_label f-input__title"
        data-cy="fields-label"
      >
        <span>{t('search.form.land_area')}</span>
      </div>
      <div className="search-form__fields_group f-input f-input--text-fields">
        <Field
          name="land_area_from"
          component="input"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          placeholder={t('search.form.from')}
          className="f-input__text-field"
          id="search-form-land-area-from"
          disabled={!isHiddenHeader}
          parse={formatNumber}
        />
        <Field
          name="land_area_to"
          component="input"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          placeholder={t('search.form.to')}
          className="f-input__text-field"
          id="search-form-land-area-to"
          disabled={!isHiddenHeader}
          parse={formatNumber}
        />
      </div>
    </div>
  );
};

LandAreaFields.propTypes = {
  isHiddenHeader: PropTypes.bool
};

export default LandAreaFields;
