import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

const SearchEmpty = ({ onClickHandler, linkText }) => {
  const { t } = useTranslation();

  return (
    <div className="search-empty">
      <div className="search-empty__icon">
        <Image
          width={30}
          height={30}
          src="/static/images/search/face-emoji.png"
          data-cy="empty-icon"
          alt=""
        />
      </div>
      <div className="search-empty__title" data-cy="empty-title">
        {t('search.nothing_found')}
      </div>
      {/* eslint-disable */}
      <a
        href="#"
        className="search-popup__content-link search-empty__link"
        onClick={onClickHandler}
        data-cy="empty-link"
      >
        {linkText}
      </a>
      {/* eslint-enable */}
    </div>
  );
};

SearchEmpty.propTypes = {
  onClickHandler: PropTypes.func,
  linkText: PropTypes.string
};

export default SearchEmpty;
