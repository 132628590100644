import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FieldOnChangeAdapter from './FieldOnChangeAdapter';

const Checkbox = ({
  name,
  value,
  labelText,
  handleChange,
  labelOnClick,
  additionalClass,
  type,
  id
}) => (
  <label // eslint-disable-line
    id={id}
    className="f-checkbox"
    onClick={labelOnClick}
  >
    <span className="f-checkbox__label" data-cy="checkbox-label-text">
      {labelText}
    </span>
    <Field
      name={name}
      component={FieldOnChangeAdapter}
      inputOnChange={handleChange}
      type={type || 'checkbox'}
      value={value}
      className={classNames('f-checkbox__field', additionalClass)}
    />
    <span className="f-checkbox__tickmark" />
  </label>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  labelOnClick: PropTypes.func,
  labelText: PropTypes.string,
  type: PropTypes.string,
  additionalClass: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string
};

export default Checkbox;
