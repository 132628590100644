import React, { useRef, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BinaModal from '../../ReactModal';
import useSearchModal from '../../../hooks/useSearchModal';
import {
  hidePopupHeader,
  showPopupHeader,
  setChosenLocationNames,
  setCurrentState
} from '../../../actions';
import SearchInput from '../SearchInput';
import ResetButton from './ResetButton';

const mapStateToProps = state => {
  return {
    isHiddenHeader: state.searchForm['popup-header'],
    resetHandler: state.searchForm.resetPopupHandler
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showPopupHeaderHandler: () => dispatch(showPopupHeader()),
    hidePopupHeaderHandler: () => dispatch(hidePopupHeader()),
    chosenLocationNames: names => dispatch(setChosenLocationNames(names)),
    mutateState: state => dispatch(setCurrentState(state))
  };
};

const SearchPopup = ({
  children,
  isOpen,
  closeHandler,
  isHiddenHeader,
  popupTitle,
  hidePopupHeaderHandler,
  showPopupHeaderHandler,
  inputPlaceholder,
  reset,
  resetHandler,
  inputMode,
  additionalClass,
  topTools,
  chosenLocationNames,
  mutateState,
  modalId
}) => {
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const formEl = useRef(null);
  const {
    searchString,
    isFocused,
    onChangeHandler,
    onFocusHandler,
    defaultCancelHandler,
    onResetHandler,
    isSubmittedForm,
    setIsSubmittedForm
  } = useSearchModal(inputEl);

  const setRef = () => {
    return formEl;
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setIsSubmittedForm(true);
    }
  };

  const headerClasses = classNames(
    'search-popup__header search-popup__header--brown',
    { 'is-hidden': !isHiddenHeader }
  );
  const cancelHandler = () => {
    showPopupHeaderHandler();
    defaultCancelHandler();
  };
  const onFocusInputHandler = () => {
    hidePopupHeaderHandler();
    onFocusHandler();
  };
  const formResetHandler = () => {
    formEl.current.reset({
      city_id: '1',
      leased: 'false',
      category_id: '1'
    });
    chosenLocationNames([]);
    mutateState({
      cityId: '1',
      leased: 'false',
      categoryId: '1'
    });
  };
  const resetButton = reset && (
    <ResetButton
      {...{
        resetHandler,
        formResetHandler
      }}
    />
  );

  return (
    <BinaModal
      modalId={modalId}
      isOpen={isOpen}
      onRequestClose={closeHandler}
      contentClassName={classNames(
        'search-popup body-scroll-lock-ignore',
        additionalClass,
        { 'search-popup--is-focused': isFocused }
      )}
      overlayClassName="search-popup__overlay body-scroll-lock-ignore"
    >
      <div className={headerClasses} data-cy="popup-header">
        <div className="search-popup__header-action">
          <button
            className="search-popup__header-btn search-popup__header-btn--close"
            onClick={closeHandler}
            data-cy="search-popup-close-btn"
          >
            close
          </button>
        </div>
        <h2 className="search-popup__header-title" data-cy="search-popup-title">
          {popupTitle}
        </h2>
        {resetButton}
      </div>
      <div className="search-popup__content" data-cy="search-popup-content">
        {isHiddenHeader && topTools}
        {inputPlaceholder && (
          <div
            className={classNames('search-popup__tools', {
              'search-popup__tools--is-focused': isFocused
            })}
            data-cy="search-popup-tools"
          >
            <SearchInput
              onFocusHandler={onFocusInputHandler}
              onChangeHandler={onChangeHandler}
              onResetHandler={onResetHandler}
              isShowReset={!!searchString}
              value={searchString}
              refEl={inputEl}
              inputMode={inputMode}
              onKeyDown={handleKeyDown}
              placeholder={inputPlaceholder}
              id="search-form-search-by-number"
            />
            {isFocused && (
              <button
                className="search-popup__content-btn search-popup__content-btn--cancel"
                onClick={cancelHandler}
              >
                {t('common.cancel')}
              </button>
            )}
          </div>
        )}
        {typeof children === 'function'
          ? children(
              searchString,
              isFocused,
              cancelHandler,
              isSubmittedForm,
              setRef
            )
          : { children }}
      </div>
    </BinaModal>
  );
};

SearchPopup.propTypes = {
  children: PropTypes.func || PropTypes.node,
  closeHandler: PropTypes.func,
  isOpen: PropTypes.bool,
  isHiddenHeader: PropTypes.bool,
  popupTitle: PropTypes.string,
  showPopupHeaderHandler: PropTypes.func,
  hidePopupHeaderHandler: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  reset: PropTypes.bool,
  resetHandler: PropTypes.func,
  inputMode: PropTypes.string,
  additionalClass: PropTypes.string,
  topTools: PropTypes.node,
  chosenLocationNames: PropTypes.func,
  mutateState: PropTypes.func,
  modalId: PropTypes.string
};

SearchPopup.defaultProps = {
  reset: false,
  inputMode: 'text'
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(SearchPopup));
