import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BinaModal from '../../ReactModal';
import SearchInput from '../SearchInput';
import useSearchModal from '../../../hooks/useSearchModal';
import { hidePopupHeader, showPopupHeader } from '../../../actions';

const mapStateToProps = state => {
  return {
    isHiddenHeader: state.searchForm['popup-header'],
    resetHandler: state.searchForm.resetPopupHandler,
    locationsResetButtonIsDisabled:
      state.searchForm.locationsResetButtonIsDisabled
  };
};
const mapDispatchToProps = dispatch => {
  return {
    showPopupHeaderHandler: () => dispatch(showPopupHeader()),
    hidePopupHeaderHandler: () => dispatch(hidePopupHeader())
  };
};

const SearchPopupLocations = ({
  children,
  isOpen,
  closeHandler,
  isHiddenHeader,
  popupTitle,
  hidePopupHeaderHandler,
  showPopupHeaderHandler,
  inputPlaceholder,
  reset,
  resetHandler,
  inputMode,
  additionalClass,
  topTools,
  locationsResetButtonIsDisabled,
  modalId
}) => {
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const formEl = useRef(null);
  const {
    searchString,
    isFocused,
    onChangeHandler,
    onBlurHandler,
    onFocusHandler,
    defaultCancelHandler,
    onResetHandler,
    isSubmittedForm,
    setIsSubmittedForm,
    isInputOnFocus
  } = useSearchModal(inputEl);

  const setRef = () => {
    return formEl;
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setIsSubmittedForm(true);
    }
  };

  const headerClasses = classNames(
    'search-popup__header search-popup__header--brown',
    { 'is-hidden': !isHiddenHeader }
  );
  const cancelHandler = () => {
    showPopupHeaderHandler();
    defaultCancelHandler();
  };
  const onFocusInputHandler = () => {
    hidePopupHeaderHandler();
    onFocusHandler();
  };
  const resetButton = reset && (
    <div className="search-popup__header-action right-text">
      <button
        className={classNames('search-popup__header-btn', {
          disabled: locationsResetButtonIsDisabled
        })}
        onClick={resetHandler}
        data-cy="search-popup-reset-btn"
      >
        <span
          className="search-popup__header-btn_text"
          data-cy="reset-btn-text"
        >
          {t('common.reset')}
        </span>
      </button>
    </div>
  );

  return (
    <BinaModal
      modalId={modalId}
      isOpen={isOpen}
      onRequestClose={closeHandler}
      contentClassName={classNames('search-popup', additionalClass)}
      overlayClassName="search-popup__overlay"
    >
      <div className={headerClasses}>
        <div className="search-popup__header-action">
          <button
            className="search-popup__header-btn search-popup__header-btn--close"
            onClick={closeHandler}
          >
            close
          </button>
        </div>
        <h2 className="search-popup__header-title" data-cy="popup-header-title">
          {popupTitle}
        </h2>
        {resetButton}
      </div>
      <div className="search-popup__content">
        {isHiddenHeader && topTools}
        {inputPlaceholder && (
          <div
            className={classNames('search-popup__tools', {
              'search-popup__tools--is-focused': isFocused
            })}
          >
            <SearchInput
              onBlurHandler={onBlurHandler}
              onFocusHandler={onFocusInputHandler}
              onChangeHandler={onChangeHandler}
              onResetHandler={onResetHandler}
              isShowReset={!!searchString}
              value={searchString}
              refEl={inputEl}
              inputMode={inputMode}
              onKeyDown={handleKeyDown}
              placeholder={inputPlaceholder}
            />
            {isFocused && (
              <button
                className="search-popup__content-btn search-popup__content-btn--cancel"
                onClick={cancelHandler}
              >
                {t('common.cancel')}
              </button>
            )}
          </div>
        )}
        {typeof children === 'function'
          ? children(
              searchString,
              isFocused,
              cancelHandler,
              isSubmittedForm,
              setRef,
              isInputOnFocus
            )
          : { children }}
      </div>
    </BinaModal>
  );
};

SearchPopupLocations.propTypes = {
  children: PropTypes.func || PropTypes.node,
  closeHandler: PropTypes.func,
  isOpen: PropTypes.bool,
  isHiddenHeader: PropTypes.bool,
  popupTitle: PropTypes.string,
  showPopupHeaderHandler: PropTypes.func,
  hidePopupHeaderHandler: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  reset: PropTypes.bool,
  resetHandler: PropTypes.func,
  inputMode: PropTypes.string,
  additionalClass: PropTypes.string,
  topTools: PropTypes.node,
  locationsResetButtonIsDisabled: PropTypes.bool,
  modalId: PropTypes.string
};

SearchPopupLocations.defaultProps = {
  reset: false,
  inputMode: 'text'
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPopupLocations);
