import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import CheckboxOrRadio from '../SearchFormCustomComponents/CheckboxOrRadio';
import { categoriesQuery } from '../../../queries';
import getCategoriesData from '../../../defaultData/categories';

const CategoriesGroup = ({ setErrorUI }) => {
  const { i18n } = useTranslation();
  const categoryLabelId = id => `search-form-category-${id}`;
  const { data, loading, error } = useQuery(categoriesQuery);
  const categoriesData =
    (loading && getCategoriesData(i18n.language)) || data?.categories;
  const categoriesRadios = categoriesData?.map(category => {
    if (category.children.length) {
      const result = [];
      const children = category.children.map(child => (
        <CheckboxOrRadio
          key={child.id}
          type="radio"
          value={child.id}
          name="category_id"
          labelText={child.name}
          id={categoryLabelId(child.id)}
        />
      ));

      result.push(
        <CheckboxOrRadio
          key={category.id}
          type="radio"
          value={category.id}
          name="category_id"
          labelText={category.name}
          id={categoryLabelId(category.id)}
        />,
        children
      );

      return result;
    }

    return (
      <CheckboxOrRadio
        key={category.id}
        type="radio"
        value={category.id}
        name="category_id"
        labelText={category.name}
        id={categoryLabelId(category.id)}
      />
    );
  });

  useEffect(() => {
    if (error) setErrorUI(true);
  }, [error]);

  return (
    <div className="search-form__category">
      <div className="f-input-group f-input-group--flex f-input-group--scrollable">
        {categoriesRadios}
      </div>
    </div>
  );
};

CategoriesGroup.propTypes = {
  setErrorUI: PropTypes.func
};

export default CategoriesGroup;
