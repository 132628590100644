import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { locationGroupDispatcher } from '../../utils/helpers';

const CheckboxButton = ({
  data,
  onChangeHandler,
  currentValues,
  name,
  withGroupType,
  labelClass,
  isFullName,
  cypress
}) => {
  const { t } = useTranslation();
  const { id, name: locationName, locationGroupId, parent, fullName } = data;

  return (
    <label className={classNames('search-checkbox-label', labelClass)}>
      <div className="search-checkbox-container">
        <input
          type="checkbox"
          name={name}
          id={`location_ids_${id}`}
          className="search-checkbox input-hidden"
          value={id}
          checked={currentValues.includes(id)}
          onChange={onChangeHandler}
          data-cy={`${cypress}-checkbox`}
        />
        <div>
          <div className="search-checkbox__text" data-cy={`${cypress}-name`}>
            {isFullName ? fullName : locationName}
          </div>
          {withGroupType && (
            <div className="search-checkbox__group-type">
              {locationGroupDispatcher(locationGroupId, parent, t)}
            </div>
          )}
        </div>
        <span className="search-checkbox__mark" />
      </div>
    </label>
  );
};

CheckboxButton.propTypes = {
  data: PropTypes.object,
  onChangeHandler: PropTypes.func,
  currentValues: PropTypes.array,
  name: PropTypes.string,
  withGroupType: PropTypes.bool,
  labelClass: PropTypes.string,
  isFullName: PropTypes.bool,
  cypress: PropTypes.string
};

export default CheckboxButton;
