import { useState, useEffect } from 'react';
import { itemQuery } from '../queries';

export default (client, searchString) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchString.length >= 5 && searchString.length <= 10) {
      (async function fetchData() {
        setLoading(true);
        const result = await client
          .query({
            query: itemQuery(searchString),
            variables: {
              id: searchString
            }
          })
          .then(response => {
            setData(response.data);
            setLoading(false);
            if (response.data.item) {
              let savedItems =
                JSON.parse(localStorage.getItem('last_ads')) || [];
              savedItems = savedItems.filter(ad => {
                return ad.id !== response.data.item.id;
              });
              if (savedItems.length === 5) {
                savedItems.unshift(response.data.item);
                savedItems.pop();
              } else {
                savedItems.unshift(response.data.item);
              }
              localStorage.setItem('last_ads', JSON.stringify(savedItems));
            }
          })
          .catch(err => {
            setError(err);
            setLoading(false);
          });

        return result;
      })();
    } else {
      setData({});
    }
  }, [searchString]);

  return {
    data,
    loading,
    error
  };
};
