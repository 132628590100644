import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useIphoneDetection from '../../../hooks/useIphoneDetection';
import Search from '..';
import AutoSuggestion from '../SearchAutoSuggestion';

const SearchFilters = ({
  cancelHandler,
  isFocused,
  searchString,
  isSubmittedForm,
  setRef,
  initialFormValues,
  togglePopup,
  sorting
}) => {
  const isIphone = useIphoneDetection();
  const filters = !isFocused && (
    <Search
      getRef={setRef}
      isFullForm={true}
      initialFormValues={initialFormValues}
      toggleFilters={togglePopup}
      sorting={sorting}
    />
  );
  const suggestions = isFocused && (
    <AutoSuggestion
      cancelHandler={cancelHandler}
      searchString={searchString}
      isFocused={isFocused}
      isSubmittedForm={isSubmittedForm}
    />
  );

  useEffect(() => {
    const filtersPopup = document.getElementsByClassName(
      'search-popup--filters'
    );
    const container = filtersPopup[0].querySelector('.search-popup__content');
    const touchHandler = () => {
      const input = filtersPopup[0].querySelector('input[type="search"]');

      input.blur();
    };

    if (isIphone) {
      container.addEventListener('touchstart', touchHandler);
    }

    return () => {
      if (isIphone) {
        container && container.removeEventListener('touchstart', touchHandler);
      }
    };
  }, [isIphone]);

  return (
    <div className="search-popup__container--scrollable">
      {filters}
      {suggestions}
    </div>
  );
};

SearchFilters.propTypes = {
  cancelHandler: PropTypes.func,
  isFocused: PropTypes.bool,
  searchString: PropTypes.string,
  isSubmittedForm: PropTypes.bool,
  setRef: PropTypes.func,
  togglePopup: PropTypes.func,
  initialFormValues: PropTypes.object,
  sorting: PropTypes.string
};

export default SearchFilters;
