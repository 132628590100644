import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utils/helpers';
import Condition from '../SearchFormCustomComponents/Condition';

const AreaFieldsGroup = ({ isHiddenHeader }) => {
  const { t } = useTranslation();

  return (
    <div className="search-form__fields search-form__fields--area f-input-group">
      <div
        className="search-form__fields_label f-input__title"
        data-cy="fields-label"
      >
        <span>{t('search.form.area')}</span>{' '}
        <Condition
          when="category_id"
          is={['1', '2', '3', '5', '6', '7', '8', '10']}
        >
          {t('search.form.units.metres')}
        </Condition>
        <Condition when="category_id" is={['9']}>
          {t('search.form.units.acres')}
        </Condition>
      </div>
      <div
        className="search-form__fields_group f-input f-input--text-fields"
        data-cy="text-fields-container"
      >
        <Field
          name="area_from"
          component="input"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          placeholder={t('search.form.from')}
          className="f-input__text-field"
          id="search-form-area-from"
          parse={formatNumber}
          disabled={!isHiddenHeader}
        />
        <Field
          name="area_to"
          component="input"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          placeholder={t('search.form.to')}
          className="f-input__text-field"
          id="search-form-area-to"
          parse={formatNumber}
          disabled={!isHiddenHeader}
        />
      </div>
    </div>
  );
};

AreaFieldsGroup.propTypes = {
  isHiddenHeader: PropTypes.bool
};

export default AreaFieldsGroup;
