import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SuggestionList from '../SearchSuggestionList';

const RecentSearches = ({ ads = [] }) => {
  const { t } = useTranslation();
  const label = t('search.ads_search.recent_searches');

  return (
    <div className="recent-searches">
      <div className="recent-searches__title">{label}</div>
      <SuggestionList data={ads} />
    </div>
  );
};

RecentSearches.propTypes = {
  ads: PropTypes.array
};

export default RecentSearches;
