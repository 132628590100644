import React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utils/helpers';
import FieldOnChangeAdapter from '../SearchFormCustomComponents/FieldOnChangeAdapter';

const FloorFieldsGroup = ({ isHiddenHeader }) => {
  const { t } = useTranslation();
  const { change } = useForm();

  const handleChange = event => {
    const floorFromValue = +event.target.value;

    if (floorFromValue === 1) {
      change('floor_first', undefined);
    }
  };

  return (
    <div
      className="search-form__fields search-form__fields--floor f-input-group"
      data-cy="fields-floor-group"
    >
      <div
        className="search-form__fields_label f-input__title"
        data-cy="fields-label"
      >
        <span>{t('search.form.floor')}</span>
      </div>
      <div
        className="search-form__fields_group f-input f-input--text-fields"
        data-cy="text-fields-container"
      >
        <Field
          name="floor_from"
          component={FieldOnChangeAdapter}
          inputOnChange={handleChange}
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          placeholder={t('search.form.from')}
          className="f-input__text-field"
          id="search-form-floor-from"
          disabled={!isHiddenHeader}
          parse={formatNumber}
        />
        <Field
          name="floor_to"
          component="input"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          placeholder={t('search.form.to')}
          className="f-input__text-field"
          id="search-form-floor-to"
          disabled={!isHiddenHeader}
          parse={formatNumber}
        />
      </div>
    </div>
  );
};

FloorFieldsGroup.propTypes = {
  isHiddenHeader: PropTypes.bool
};

export default FloorFieldsGroup;
