import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxOrRadio from '../SearchFormCustomComponents/CheckboxOrRadio';

const RoomsGroup = () => {
  const { t } = useTranslation();

  return (
    <div className="search-form__rooms">
      <div className="f-input-group f-input-group--flex">
        {['1', '2', '3', '4', '5+'].map((value, key) => (
          <CheckboxOrRadio
            key={key}
            type="checkbox"
            value={value}
            name="room_ids"
            labelText={value === '5+' ? t('search.form.five_rooms') : value}
            id={`search-form-room-${key + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default RoomsGroup;
