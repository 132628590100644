import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { locationGroupsLightQuery } from '../queries';

const useLocationsPlaceholder = cityId => {
  const [locationsPlaceholder, setLocationsPlaceholder] = useState(null);

  const { t } = useTranslation();

  const setPlaceholder = locationGroups => {
    let placeholder;
    switch (locationGroups.length) {
      case 1:
        placeholder = t(
          `search.locations_search.locations_placeholder.group_${locationGroups.join()}`
        );
        break;
      case 2:
        placeholder = t(
          'search.locations_search.locations_placeholder.region_and_landmark'
        );
        break;
      case 3:
        placeholder = t(
          'search.locations_search.locations_placeholder.all_groups'
        );
        break;
      default:
        break;
    }

    return placeholder;
  };

  const locationGroupsLightQueryVariables = {
    cityId,
    ids: [1, 2, 4]
  };

  const { data } = useQuery(
    locationGroupsLightQuery(locationGroupsLightQueryVariables),
    {
      variables: locationGroupsLightQueryVariables
    }
  );

  useEffect(() => {
    if (!data) return;
    const { locationGroups: lg } = data;
    const locationGroups = lg
      ?.filter(g => g.locations.length > 0)
      .map(l => l.id);
    setLocationsPlaceholder(setPlaceholder(locationGroups));
  }, [data]);

  return locationsPlaceholder;
};

export default useLocationsPlaceholder;
