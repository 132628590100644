import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import CheckboxButton from '../../CheckboxButton';

const TabContent = ({
  locations,
  chosenLocationIds,
  onChangeCheckboxHandler,
  setTownshipsCount,
  townships,
  isFullName
}) => {
  const getTownshipsCount = () => {
    if (!townships) return null;
    let locationsCount = 0;
    townships.forEach(township => {
      if (chosenLocationIds.includes(township.id)) locationsCount += 1;
    });

    return locationsCount;
  };

  useEffect(() => {
    townships && setTownshipsCount(getTownshipsCount());
  }, [chosenLocationIds]);

  return (
    <div className="search-checkbox-list">
      {locations.map(location => {
        const { id } = location;
        const nestedTownships = townships?.filter(ts => ts.parent.id === id);
        const labelClass = nestedTownships
          ? 'search-checkbox-label--region'
          : null;
        return (
          <Fragment key={id}>
            <CheckboxButton
              {...{ labelClass, isFullName }}
              data={location}
              onChangeHandler={onChangeCheckboxHandler}
              currentValues={chosenLocationIds}
              name="search-locations"
              cypress="location"
            />
            {nestedTownships?.length > 0 && (
              <>
                {nestedTownships.map(township => (
                  <CheckboxButton
                    key={township.id}
                    data={township}
                    onChangeHandler={onChangeCheckboxHandler}
                    currentValues={chosenLocationIds}
                    name="search-locations"
                    cypress="location"
                  />
                ))}
              </>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

TabContent.propTypes = {
  locationGroupId: PropTypes.string,
  locations: PropTypes.array,
  chosenLocationIds: PropTypes.array,
  onChangeCheckboxHandler: PropTypes.func,
  currentCityId: PropTypes.string,
  setTownshipsCount: PropTypes.func,
  townships: PropTypes.array,
  isFullName: PropTypes.bool
};

export default TabContent;
