import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

const CheckboxOrRadio = ({ name, value, labelText, type, id }) => (
  <label id={id} className="f-input">
    <Field
      name={name}
      component="input"
      type={type}
      value={value}
      className="f-input__field"
    />
    <span className="f-input__label" data-cy="checkbox-or-radio-label">
      {labelText}
    </span>
  </label>
);

CheckboxOrRadio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  id: PropTypes.string
};

export default CheckboxOrRadio;
