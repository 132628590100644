import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../SearchFormCustomComponents/Checkbox';

const BuildingTypeGroup = () => {
  const { t } = useTranslation();

  return (
    <div className="search-form__group">
      <div
        className="search-form__fields_label f-input__title"
        data-cy="fields-label"
      >
        {t('search.form.building_type_group.title')}
      </div>
      <div className="f-input-group">
        <Checkbox
          name="building_type"
          labelText={t('search.form.building_type_group.business')}
          id="search-form-business-center"
          value={'BUSINESS_CENTER'}
        />
        <Checkbox
          name="building_type"
          labelText={t('search.form.building_type_group.apartment')}
          id="search-form-house_flat"
          value={'APARTMENT'}
        />
        <Checkbox
          name="building_type"
          labelText={t('search.form.building_type_group.villa')}
          id="search-form-villa"
          value={'VILLA'}
        />
      </div>
    </div>
  );
};

export default BuildingTypeGroup;
