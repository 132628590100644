// Component for debugging, not for production
import React from 'react';

const size = 30;
const styles = {
  position: 'absolute',
  top: '0',
  right: '0',
  textAlign: 'center',
  height: `${size}px`,
  width: `${size}px`,
  lineHeight: `${size}px`,
  borderRadius: `${size / 2}px`,
  border: '1px solid #ddd',
  background: '#eee'
};

export default class RenderCount extends React.Component {
  renders = 0;

  render() {
    this.renders += 1;

    return (
      <div style={{ position: 'relative' }}>
        <span style={styles}>{this.renders}</span>
      </div>
    );
  }
}
