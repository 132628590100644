const getCategoriesData = lang => {
  switch (lang) {
    case 'ru':
      return [
        {
          id: '1',
          name: 'Квартира',
          pluralName: 'Квартиры',
          slug: 'menziller',
          children: [
            {
              id: '2',
              name: 'Новостройка',
              pluralName: 'Новостройки',
              slug: 'menziller/yeni-tikili',
              __typename: 'Category'
            },
            {
              id: '3',
              name: 'Вторичка',
              pluralName: 'Вторички',
              slug: 'menziller/kohne-tikili',
              __typename: 'Category'
            }
          ],
          __typename: 'Category'
        },
        {
          id: '5',
          name: 'Дом / Дача',
          pluralName: 'Дома / Дачи',
          slug: 'heyet-evleri',
          children: [],
          __typename: 'Category'
        },
        {
          id: '7',
          name: 'Офис',
          pluralName: 'Офисы',
          slug: 'ofisler',
          children: [],
          __typename: 'Category'
        },
        {
          id: '8',
          name: 'Гараж',
          pluralName: 'Гаражи',
          slug: 'qarajlar',
          children: [],
          __typename: 'Category'
        },
        {
          id: '9',
          name: 'Участок',
          pluralName: 'Участки',
          slug: 'torpaq',
          children: [],
          __typename: 'Category'
        },
        {
          id: '10',
          name: 'Объект',
          pluralName: 'Объекты',
          slug: 'obyektler',
          children: [],
          __typename: 'Category'
        }
      ];
    default:
      return [
        {
          id: '1',
          name: 'Mənzil',
          pluralName: 'Mənzillər',
          slug: 'menziller',
          children: [
            {
              id: '2',
              name: 'Yeni tikili',
              pluralName: 'Yeni tikililər',
              slug: 'menziller/yeni-tikili',
              __typename: 'Category'
            },
            {
              id: '3',
              name: 'Köhnə tikili',
              pluralName: 'Köhnə tikililər',
              slug: 'menziller/kohne-tikili',
              __typename: 'Category'
            }
          ],
          __typename: 'Category'
        },
        {
          id: '5',
          name: 'Həyət evi / Bağ evi',
          pluralName: 'Həyət evləri / Bağ evləri',
          slug: 'heyet-evleri',
          children: [],
          __typename: 'Category'
        },
        {
          id: '6',
          name: 'Bağ',
          pluralName: 'Bağlar',
          slug: 'baglar',
          children: [],
          __typename: 'Category'
        },
        {
          id: '7',
          name: 'Ofis',
          pluralName: 'Ofislər',
          slug: 'ofisler',
          children: [],
          __typename: 'Category'
        },
        {
          id: '8',
          name: 'Qaraj',
          pluralName: 'Qarajlar',
          slug: 'qarajlar',
          children: [],
          __typename: 'Category'
        },
        {
          id: '9',
          name: 'Torpaq',
          pluralName: 'Torpaq',
          slug: 'torpaq',
          children: [],
          __typename: 'Category'
        },
        {
          id: '10',
          name: 'Obyekt',
          pluralName: 'Obyektlər',
          slug: 'obyektler',
          children: [],
          __typename: 'Category'
        }
      ];
  }
};

export default getCategoriesData;
