import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import Checkbox from '../SearchFormCustomComponents/Checkbox';

const RentalPeriod = () => {
  const { t } = useTranslation();
  const { getFieldState, change } = useForm();
  const [fakeFieldState, setFakeFieldState] = useState(false);

  const handleChange = event => {
    const fieldState = getFieldState('paid_daily');
    const { value: fieldValue } = fieldState;
    const targetValue = event.target.value;

    setFakeFieldState(fieldValue.length > 1);

    if (fieldValue.length !== 1) {
      change('paid_daily', undefined);
    }
    if (fakeFieldState) {
      change('paid_daily', [`${targetValue !== 'true'}`]);
    }
  };

  return (
    <>
      {[
        {
          value: 'false',
          labelText: t('search.form.paid_monthly'),
          id: 'search-form-paid-monthly'
        },
        {
          value: 'true',
          labelText: t('search.form.paid_daily'),
          id: 'search-form-paid-daily'
        }
      ].map(({ value, labelText, id }, key) => (
        <Checkbox
          {...{ value, labelText, id, handleChange }}
          key={key}
          name="paid_daily"
          additionalClass={fakeFieldState && 'fake-checked'}
        />
      ))}
    </>
  );
};

export default RentalPeriod;
