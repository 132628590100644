import React from 'react';
import PropTypes from 'prop-types';

const FieldOnChangeAdapter = ({ input, inputOnChange, ...rest }) => {
  const noMeta = ({ meta, ...restParams }) => restParams;
  const inputProps = {
    ...input,
    onChange: e => {
      input.onChange(e);
      inputOnChange && inputOnChange(e);
    }
  };

  return <input {...inputProps} {...noMeta(rest)} />;
};

FieldOnChangeAdapter.propTypes = {
  input: PropTypes.object,
  inputOnChange: PropTypes.func,
  className: PropTypes.string
};

export default FieldOnChangeAdapter;
