import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxOrRadio from '../SearchFormCustomComponents/CheckboxOrRadio';

const LeasedGroup = () => {
  const { t } = useTranslation();

  return (
    <div className="search-form__leased">
      <div className="f-input-group f-input-group--flex">
        {[
          {
            value: 'false',
            labelText: t('search.form.buy'),
            id: 'search-form-buy'
          },
          {
            value: 'true',
            labelText: t('search.form.lease'),
            id: 'search-form-rent'
          }
        ].map(({ value, labelText, id }, key) => (
          <CheckboxOrRadio
            {...{ value, labelText, id }}
            key={key}
            type="radio"
            name="leased"
          />
        ))}
      </div>
    </div>
  );
};

export default LeasedGroup;
